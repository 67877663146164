<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right d-flex">
            <!-- <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Locations</li>
            </ol> -->
            <div class="d-flex">
              <label class="col-form-label me-2">Budget Year</label>
              <div style="width: 150px">
                <v-select label="name" v-model="year" :options="years" :clearable="false" :reduce="(name) => name.id"
                  @option:selected="getPlansByYear($event)">
                </v-select>
              </div>
            </div>
            <div @click="refreshData" class="icon-css">
              <i style="color: #4a81d4" class="fe-rotate-cw"></i>
            </div>
          </div>
          <h4 class="page-title">Monthly Schedule Plan</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-end" v-if="!loading">
              <!-- <router-link
                :to="`/admin/monthly-plans/${current_budgetyear_id}/add-plan`"
              > -->
              <router-link :to="`/admin/monthly-plans/add-plan`">
                <button type="button" class="btn btn-xs btn-blue waves-effect waves-light mb-2 mx-2"
                  v-if="hasPermissions(['monthly-plan-create'])">
                  <i class="mdi mdi-plus-circle"></i>
                  Add Monthly Plan
                </button>
              </router-link>
            </div>
            <place-holder v-if="loading"></place-holder>

            <div class="table-responsive" v-if="!loading">
              <table class="table table-striped dt-responsive w-100 mb-3" id="yearly-plan-table" v-if="!loading">
                <thead v-if="!loading">
                  <tr>
                    <th>No.</th>
                    <th>Plan Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(plan, index) in filterDatas" :key="index">
                    <td>{{ ++index }}</td>
                    <td>
                      {{ plan.name }}
                    </td>
                    <td>
                      <badgeSuccess v-if="current_day >= plan.first_day &&
                        current_day <= plan.last_day
                        " data-bs-toggle="tooltip" title="Plan is start!" style="cursor: pointer" :name="'Active'" />
                      <badgeInfo v-else-if="current_day < plan.first_day" data-bs-toggle="tooltip"
                        title="Plan is upcoming!" style="cursor: pointer" :name="'Upcoming'" />
                      <badgeDanger v-else data-bs-toggle="tooltip" title="Plan is Over!" style="cursor: pointer"
                        :name="'Inactive'" />
                    </td>
                    <td>
                      <router-link :to="{
                        name: 'monthly-plans-view-lists',
                        params: {
                          id: plan.budget_year_id,
                          month: plan.month,
                        },
                      }">
                        <button type="button" class="
                                btn btn-xs btn-blue
                                waves-effect waves-light
                                mb-2
                              ">
                          <i class="fas fa-eye"></i>
                          View
                        </button>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Modules
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import badgeSuccess from "../../components/shared/badgeSuccess.vue";
import badgeDanger from "../../components/shared/badgeDanger.vue";
import badgeInfo from "../../components/shared/badgeInfo.vue";

//Mixins
import userHasPermissions from "../../mixins/userHasPermissions";

export default {
  components: {
    vSelect,
    badgeSuccess,
    badgeDanger,
    badgeInfo,
  },
  mixins: [userHasPermissions],
  data() {
    return {
      year: "",
      yearId: "",
      years: [],
      allMonthlyPlans: [],
      eachOneMonth: [],
      filterDatas: [],
      current_budgetyear_id: "",
      current_year_month: "",
      current_day: "",
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getBudgetYear() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v1/yearly-training-plans`)
        .then((response) => {
          this.years = response.data.data;
          this.loading = false;
        });
    },
    async getPlansByYear(value) {
      this.yearId = value.id;
      this.allMonthlyPlans = "";
      this.filterDatas = [];
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/show-plan-lists?budget_year_id=${this.yearId}`
        )
        .then((response) => {
          this.allMonthlyPlans = response.data.data;

          this.eachOneMonth = this.allMonthlyPlans.filter(
            (elem, index, arr) =>
              index ===
              arr.findIndex(
                (t) =>
                  t.month === elem.month &&
                  t.budget_year_id === elem.budget_year_id
              )
          );

          this.eachOneMonth.map((array) => {
            var endOfMonth = moment(array.to_date)
              .clone()
              .endOf("month")
              .format("YYYY-MM-DD");

            var firstOfMonth = moment(array.to_date)
              .clone()
              .startOf("month")
              .format("YYYY-MM-DD");

            var data = {
              name: array.name,
              last_day: endOfMonth,
              first_day: firstOfMonth,
              budget_year_id: array.budget_year_id,
              month: array.month,
            };

            this.filterDatas.push(data);
          });

          this.loading = false;
          this.$Progress.finish();
        });
      $("#yearly-plan-table").DataTable();
    },
    async getAllMonthlyPlans() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/monthly-plans`)
        .then((response) => {
          this.allMonthlyPlans = response.data.data;

          this.eachOneMonth = this.allMonthlyPlans.filter(
            (elem, index, arr) =>
              index ===
              arr.findIndex(
                (t) =>
                  t.month === elem.month &&
                  t.budget_year_id === elem.budget_year_id
              )
          );

          this.eachOneMonth.map((array) => {
            // var getYear = moment(array.to_date).format("YYYY");
            // var getMonth = moment(array.to_date).format("MM");
            // var date = new Date(getYear, getMonth);
            // var LastDay = new Date(date.getFullYear(), date.getMonth(), 1);
            // var getLastDay = JSON.stringify(LastDay).slice(1, 11);

            var endOfMonth = moment(array.to_date)
              .clone()
              .endOf("month")
              .format("YYYY-MM-DD");

            var firstOfMonth = moment(array.to_date)
              .clone()
              .startOf("month")
              .format("YYYY-MM-DD");

            var data = {
              name: array.name,
              last_day: endOfMonth,
              first_day: firstOfMonth,
              budget_year_id: array.budget_year_id,
              month: array.month,
            };

            this.filterDatas.push(data);
          });

          this.loading = false;
          this.$Progress.finish();
        });
      $("#yearly-plan-table").DataTable();
    },
    async getCurrentBudgetYear() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/latest-budgetyear`)
        .then((response) => {
          this.current_budgetyear_id = response.data.data.id;
          this.loading = false;
          this.$Progress.finish();
        });
    },
    refreshData() {
      this.year = "";
      this.yearId = "";
      this.allMonthlyPlans = "";
      this.filterDatas = [];
      this.getBudgetYear();
      this.getAllMonthlyPlans();
      this.getCurrentBudgetYear();
      this.current_year_month = moment().format("YYYY-MM");
      this.current_day = moment().format("YYYY-MM-DD");
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#yearly-plan-table").DataTable().draw();
    },
  },
  created() {
    this.clearFilter();
    this.getBudgetYear();
    this.getAllMonthlyPlans();
    this.getCurrentBudgetYear();
    this.current_year_month = moment().format("YYYY-MM");
    this.current_day = moment().format("YYYY-MM-DD");
  },
};
</script>

<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>